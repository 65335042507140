import React from "react";
import { Container, Box, Heading, Text } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import { colors } from "../style_dependencies/variables";

import ButtonLink from "../components/button-link";
import SEO from "components/seo";

const seoData = {
  title: "Fapshi - 404! Page Not found",
};

const buttonData = {
  btnName: "Go To Homepage",
  btnURL: "/",
  btnType: "coloredBtn",
};

const styles = {
  root: {
    m: "auto",
    pb: "5%",
  },
  heading: {
    textAlign: "center",
    fontSize: "2.5rem",
    fontWeight: 700,
    lineHeight: 1,
    color: colors.btnColor,
    mt: "5%",
  },
  text: {
    textAlign: "center",
    fontSize: "1rem",
    mt: "1rem",
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
  },
  image: {
    display: "block",
    maxWidth: "100%",
    width: 560,
    m: "0 auto",
    marginTop: "4rem",
  },
};

const NotFoundView = () => {
  return (
    <div sx={styles.root}>
      <SEO data={seoData} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Heading as="h4" sx={styles.heading}>
            404: The page you are looking for isn’t here
          </Heading>
          <Text as="p" sx={styles.text}>
            You either tried some shady route or you came here by mistake.
          </Text>
          <Box sx={styles.btn}>
            <ButtonLink data={buttonData} />
          </Box>
          <Box sx={styles.image}>
            <StaticImage
              src="../assets/images/undraw_page_not_found_su7k.svg"
              alt="404 error"
              placeholder="blurred"
            />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default NotFoundView;
